import React, { Component } from 'react';

import Header from '../../components/Header'

export default class Main extends Component{
    render(){
        return(
            <div className='Main'>
                <Header />
            </div>
        );
    }
} 