import React from 'react';
import './styles.css';
import './assents/css/fonts.css';
import './assents/css/icomoon.css';
import './assents/css/animate.css';

import Main from './pages/Main';

const App = () => {
  return (
    <Main />
  );
}

export default App;
