import React from 'react';

import './styles.css';

const Header = () => {
    return (
        <header>
            <div className="display-t js-fullheight">
                <div className="display-tc js-fullheight animate-box" data-animate-effect="fadeIn">
                    <div className="profile-thumb animated to-animate fadeInUp"></div>
                    <h2 className="animated to-animate fadeInUp"><span>Mateus Fernandes Doimo</span></h2>
                    <h3 className="animated to-animate fadeInUp"><span><a target="_blank" rel="noopener noreferrer" href="https://icmc.usp.br/pessoas?id=21383945">Computer Engineer</a></span></h3>
                    <ul className="fh5co-social-icons animated to-animate  fadeInUp">
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/mateusdoimo"><i className="icon-facebook2"></i></a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/mateusdoimo"><i className="icon-twitter2"></i></a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/mateusdoimo"><i className="icon-instagram"></i></a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/mateusdoimo"><i className="icon-linkedin2"></i></a></li>
                        <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/mateusdoimo"><i className="icon-github"></i></a></li>
                    </ul>
                </div>
            </div>
        </header>
    );
}

export default Header;